import React, { useEffect } from "react";
import { ZoomMtg } from "@zoomus/websdk";
import "./style.css";

ZoomMtg.preLoadWasm();
ZoomMtg.prepareWebSDK();
// loads language files, also passes any error messages to the ui
ZoomMtg.i18n.load("en-US");
ZoomMtg.i18n.reload("en-US");

const role = 0;
const apiKey = process.env.REACT_APP_API_KEY;
const urlCallback = process.env.REACT_APP_DOMAIN_URL;

const Zoom = ({ meetingNumber, username, userEmail, password }) => {
  useEffect(() => {
    ZoomMtg.setZoomJSLib("https://source.zoom.us/2.1.1/lib", "/av");
    ZoomMtg.preLoadWasm();
    ZoomMtg.prepareJssdk();
    getSignature();
  }, []);

  const getSignature = () => {
    fetch(
      `https://testvu.tedesco.es/v1/api/zoom/getSignature?meetingNumber=${meetingNumber}&role=${role}`,
      {
        method: "GET",
        headers: { "Content-Type": "application/json" },
      }
    )
      .then((res) => res.json())
      .then((response) => {
        startMeeting(response.signature);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const startMeeting = (signature) => {
    debugger;
    document.getElementById("zmmtg-root").style.display = "block";

    ZoomMtg.init({
      leaveUrl: urlCallback,
      success: (success) => {
        console.log(success);

        ZoomMtg.join({
          signature: signature,
          meetingNumber: meetingNumber,
          userName: username,
          apiKey: apiKey,
          userEmail: userEmail,
          passWord: password,
          success: (_success) => {
            console.log(_success);
          },
          error: (_error) => {
            console.log(_error);
          },
        });
      },
      error: (error) => {
        console.log(error);
      },
    });
  };

  return <div>Zoom</div>;
};

export default Zoom;
