import React, { useState } from "react";
import logo from "./assets/img/logo.naranja.png";
import "./App.css";
import Zoom from "./components/zoom";

function App() {
  const [joinMeeting, setJoinMeeting] = useState(false);
  const [meetingNumber, setMeetingNumber] = useState("");
  const [username, setUsername] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [password, setPassword] = useState(null);

  const HandleJoinMeeting = () => setJoinMeeting(!joinMeeting);

  return (
    <div className="App">
      {!joinMeeting ? (
        <header className="App-header">
          <img src={logo} className="App-logo" alt="logo" />
          <p>Ingrese los datos requeridos para unirse a la reunión</p>
          <div className="container">
            <input
              className="vu-input-text item"
              type="text"
              placeholder="Meeting Number"
              defaultValue={meetingNumber}
              onChange={(e) => setMeetingNumber(e.target.value)}
            ></input>
            <input
              className="vu-input-text item"
              type="text"
              placeholder="Username"
              defaultValue={username}
              onChange={(e) => setUsername(e.target.value)}
            ></input>
            <input
              className="vu-input-text item"
              type="email"
              placeholder="User Email"
              defaultValue={userEmail}
              onChange={(e) => setUserEmail(e.target.value)}
            ></input>
            <input
              className="vu-input-text item"
              type="password"
              placeholder="Password Meet"
              defaultValue={password}
              onChange={(e) => setPassword(e.target.value)}
            ></input>
          </div>

          <button className="vu-btn" onClick={HandleJoinMeeting}>
            Join Meeting
          </button>
        </header>
      ) : (
        <Zoom
          meetingNumber={meetingNumber}
          username={username}
          userEmail={userEmail}
          password={password}
        />
      )}
    </div>
  );
}

export default App;
